.OnlineOfflineView_main {
  position: absolute;
  max-height: 70%;
  max-width: 70%;
  right: 0%;
  margin-right: 10px;
  margin-top: 60px;
  z-index: 1;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  overflow: auto;
  box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);
}
.OnlineOfflineView_offlineHeader {
  color: red;
  font-size: 1em;
}
.OnlineOfflineView_onlineHeader {
  color: green;
  font-size: 1em;
}
.OnlineOfflineView_whosOnlineButton {
  position: absolute;
  width: 120px;
  text-align: center;
  padding: 10px;
  right: 10px;
  top: 10px;
  background-color: white;
  color: black;
  border-radius: 50px;
  z-index: 2;
  box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);
}
.OnlineOfflineView_collapseButton {
  position: absolute;
  width: 120px;
  text-align: center;
  padding: 5px;
  right: 10px;
  top: 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 1px 1px 11px -4px rgba(0, 0, 0, 0.75);
}

.MapView_911Button {
  position: absolute;
  width: 200px;
  text-align: center;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background-color: white;
  color: black;
  border-radius: 5px;
  z-index: 1;
}

.AudioConferenceContainer {
  position: absolute;
  height: 70%;
  width: fit-content;
  right: 0%;
  margin-right: 10px;
  margin-top: 50px;
  z-index: 1;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
}
